import React, { Fragment, useEffect, useState } from 'react'
import { send, useFibaMsgBox, useLoading } from '../components';
import { Menu, Transition } from '@headlessui/react'
import { BellIcon } from '@heroicons/react/24/outline'
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators'
import { useNavigate } from 'react-router-dom'


export default function Notification({ unReadNotifCount, setUnreadNotifCount, handleNotifClick }) {
    const { showLoading } = useLoading();
    const { error } = useFibaMsgBox();
    const navigate = useNavigate()

    const [notifs, setData] = useState([]);

    const getAllNotifications = async () => {
        //API_GET_NOTIFICATION
        const res = await send('lo000603', { limit: 50 }, showLoading);
        if (res.status === 'error') {
            error(res.message);
        } else {
            setData(res);
        }
    }

    const seenNotif = async (element) => {
        if (element.is_read === 0 || element.is_read === '0') {

            const res = await send('lo000602', { id: element.id }, showLoading);

            if (res.status === 'error') {
                error(res.message);
            } else {
                setUnreadNotifCount(unReadNotifCount - 1)
                for (let index = 0; index < notifs.length; index++) {
                    const element1 = notifs[index];
                    if (element1.id === element.id) {
                        notifs[index].is_read = '1';
                        setData([...notifs])
                        break;
                    }
                }
                navigate(`/menu/problems`, { state: { dataitem: { id: element.key } } });
            }
        }
    }

    useEffect(() => {
        getAllNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Menu as="div" className="relative">
            <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full text-sm  bg-white
                focus:outline-none focus:ring-2 focus:ring-primary-500 hover:text-gray-700"
                    onClick={handleNotifClick}>
                    <BadgeContainer>
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-7 w-7" aria-hidden="true" />
                        <Badge>{unReadNotifCount > 0 ? unReadNotifCount : 0}</Badge>
                    </BadgeContainer>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute -right-10 w-[90vw] sm:w-[400px] sm:right-0 z-20 mt-2 px-1 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    style={{ maxHeight: "40vh", overflowY: 'auto' }}
                >
                    {notifs.map((element, index) => (
                        <Menu.Item key={index}>
                            {({ active }) => (
                                <>
                                    <div
                                        className={`rounded cursor-pointer items-center px-4 mb-1 py-2 text-sm ${element.is_read === 0 ? 'text-white bg-primary-500 hover:bg-primary-400' : 'text-gray-700 hover:bg-gray-200'}`}
                                    >
                                        <div onClick={() => { seenNotif(element) }}>
                                            <div className="font-bold">{element.title}</div>
                                            <div className="text-xs">{element.description}</div>
                                            <div className='flex justify-between mt-2'>
                                                <div className="text-xs">{element.created_at}</div>
                                                <div className="order-last text-xs">{element.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>

    )
}
