import { Field } from "@progress/kendo-react-form";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { send, FibaInput, SetCookie, useLoading, Form, ChangeLanguage } from '../components';
import { TOKEN_NAME } from "../constants/token.const";
import AuthContext from "../contexts/AuthContext";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { FibaPassInput } from "../components/input/input-text/FibaPassInput";
import { encrtypth } from "../service/CoreFunctions";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";

export default function Login() {
    const { t } = useTranslation();
    const { setAuth } = useContext(AuthContext)
    const { showLoading } = useLoading()
    const navigate = useNavigate()
    const [errorMsg, setError] = useState();
    const [googleAuth, setGoogleAuth] = useState({ auth_code: "", id: '', google_auth_key: '' });
    const [useGoogleAuth, setGoogle] = useState(false);
    const [values, setValues] = useState({
        email: "",
        password: "",
    });

    const login = async (formdata) => {
        setError(null)
        setValues(formdata)
        const senddata = {
            ...formdata,
            password: encrtypth(formdata.password)
        };
        const res = await send("lo000100", senddata, showLoading, true);
        if (res.status === 'error') {
            setError(res.message)
        } else {
            sessionStorage.clear()
            localStorage.clear()
            checkedLogin(res);
        }
    }

    const checkedLogin = (res) => {
        if (res?.status === 'error') {
            setError(res.message);
        } else {
            if (res.use_google_auth) {
                googleAuth.id = res.id;
                googleAuth.google_auth_key = res.google_auth_key;
                setGoogle(true);
            } else {
                SetCookie(TOKEN_NAME, res.token);
                localStorage.setItem('MeCoreTokenTime', res.token_time);
                setAuth(res);
                localStorage.setItem('ME_LOGINUSER', res.user.userid);
                navigate('/');
            }
        }
    }

    const checkGoogleAuth = async () => {
        if (googleAuth.auth_code) {
            login({
                ...values,
                secret: googleAuth.google_auth_key,
                code: googleAuth.auth_code
            });
        } else {
            setError('Баталгаажуулах код оруулна уу.')
        }
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13 || e.charCode === 13) {
            checkGoogleAuth();
        }
    };

    return (
        <div className="min-h-screen bg-cover grid grid-cols-1">
            <div className="col-span-2" style={{ backgroundColor: "#F9F9FA" }}>
                <div className="absolute top-1 right-2">
                    <ChangeLanguage />
                </div>
                <Form onSubmit={login} className="space-y-6" showAction={false}>
                    <div className="flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <img
                                className="mx-auto h-16 w-auto"
                                src="/mecore-logos/main-logo/logo.png"
                                alt="Me Core"
                            />
                            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 uppercase">
                                {t('welcome')}
                            </h2>
                            <h2 className="mt-6 text-center text-2xl  tracking-tight text-gray-600 uppercase">
                                {t('login')}
                            </h2>
                        </div>

                        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                            <div className="bg-white py-8 px-4 sm:px-8 space-y-6">
                                <Field
                                    name={'email'} component={FibaInput}
                                    label={t('username')} required={true}
                                    placeholder={t('email')}
                                    autoComplete={'email'}
                                />

                                <Field
                                    name={'password'} component={FibaPassInput}
                                    placeholder="••••••••"
                                    autoComplete={'current-password'}
                                    label={t('password')} type="password" required
                                />

                                {errorMsg && <div className="rounded-md bg-red-50 p-2">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-red-800">{errorMsg}</h3>
                                        </div>
                                    </div>
                                </div>}

                                <div className="my-8">
                                    <button
                                        type="submit"
                                        className="flex w-full justify-center rounded-md border border-transparent bg-secondary-500 py-2 px-4 text-base font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2"
                                    >
                                        {t('login')}
                                    </button>
                                    <Link
                                        to="/registration"
                                        className="my-2 flex w-full justify-center rounded-md border border-transparent border-secondary-500 py-2 px-4 text-base font-medium text-secondary-500 shadow-sm hover:bg-secondary-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2"
                                    >
                                        {t('register')}
                                    </Link>
                                </div>

                                <div className="flex items-center justify-center focus:border-primary-500 focus:ring-primary-500">
                                    <div className="text-sm">
                                        <Link to={'/forgotpassword'} className="font-medium text-primary-600 hover:text-primary-500">{t('forgotpass')}</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center mt-16">
                                <div>
                                    <h3 style={{ color: "#9E9E9E" }}>&copy; {(new Date()).getFullYear()} Khan Altai Resource LLC. All rights Reserved</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div >
            {
                useGoogleAuth && (
                    <Dialog width={400} height={350} className="pt-0 px-0">
                        <div className="text-center">
                            <div className="px-0 py-3">
                                <div className='py-3 bg-slate-200 rounded-lg'>
                                    <img src={'/mecore-logos/Google_Authenticator.png'} alt="GoogleAuthLogo" className='mx-auto' />
                                    <h3 className="text-xl">{t('two_step_verify')}</h3>
                                </div>
                                <div className="my-6 text-left">
                                    <FibaInput
                                        type="number"
                                        label="Google Authenticator Код"
                                        autoComplete={'one-time-code'}
                                        onKeyPress={handleKeypress}
                                        value={googleAuth.auth_code} onChange={(event) => {
                                            googleAuth.auth_code = event.target.value;
                                            setGoogleAuth({ ...googleAuth })
                                        }}
                                    />
                                </div>
                                {errorMsg && <div className="rounded-md bg-red-50 p-2 my-3">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-red-800">{errorMsg}</h3>
                                        </div>
                                    </div>
                                </div>}
                                <Button
                                    themeColor="primary"
                                    fillMode="flat"
                                    onClick={() => {
                                        setGoogle(false)
                                    }}>
                                    {t('cancel')}
                                </Button>
                                <Button themeColor="primary" fillMode="outline" onClick={checkGoogleAuth}>
                                    {t('confirm')}
                                </Button>

                            </div>
                        </div>
                    </Dialog>
                )
            }
        </div >
    )
}
