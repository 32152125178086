import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { Error } from '@progress/kendo-react-labels'
import React, { useState } from 'react'
// import styles from './styles.module.css'

// export const ExampleComponent = ({ text }) => {
//   return <div className={styles.test}>Example Component: {text}</div>
// }

export const FibaPassInput = (fieldRenderProps) => {
  const {
    validationMessage,
    visited,
    required,
    label,
    fieldInfo,
    disabled,
    value,
    name,
    modified,
    valid,
    touched,
    ...others
  } = fieldRenderProps

  const [showPass, setShowPass] = useState(false);

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
        {required && <span className='k-required'>*</span>}
        {fieldInfo && <span className='k-field-info'>{fieldInfo}</span>}
      </label>
      <div className={`relative mt-1`}>
        <input
          required={required}
          disabled={disabled}
          modified={modified ? modified + "" : ""}
          touched={touched ? touched + "" : ""}
          valid={valid ? valid + "" : ""}
          className="k-input k-input-md k-rounded-md k-input-solid"
          id={name}
          {...others}
          value={value || ''}
          style={{ width: '100%' }}
          type={showPass ? 'text' : 'password'}
        />
        <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
          onClick={() => {setShowPass(!showPass)}}
        >
          {showPass && <EyeSlashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
          {!showPass && <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
        </div>
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    </div>

  )
}
