/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Error } from "@progress/kendo-react-labels";
import { Dialog } from "@progress/kendo-react-dialogs";

const defaultDialog = () => {
    return <Error>dialogscreen attirbute тодорхойлж өгөөгүй л байна даа.</Error>;
};

export const FibaBrowse = React.forwardRef((props, ref) => {
    const {
        // The meta props of the Field.
        validationMessage,
        visited,
        required,
        label,
        fieldInfo,
        title,
        dialogscreen,
        valueField,
        textField,
        textField2,
        browseText,
        browseText2,
        name,
        width,
        height,
        viewName,
        controlDisabled,
        passdata,
        // The input props of the Field.
        onChange,
        onFocus,
        onBlur,
        // Return choosed data
        onResult,
        // Return when click on text
        onTap,
        // The custom props that you passed to the Field.
        allowKeyDown = false,
        ...others
    } = props;
    let value = props.value ? props.value : "";
    const [isOpenDialog, setOpenDialog] = React.useState(false);
    const divRef = React.useRef(null);
    const textFieldRef = React.useRef(null);
    const [text, setText] = React.useState(browseText);
    const DialogScreen = dialogscreen ? dialogscreen : defaultDialog;
    const [focused, setFocus] = React.useState(false);
    const [chosenData, setChosenData] = React.useState({});
    const [inputValue, setInputValue] = React.useState(value);
    const browseBtnCss = {
        // fontSize: '15px',
        // marginLeft: '3px',
        // // border: '1px solid #424242',
        // borderColor: 'rgba(0, 0, 0, 0.08)',
        // borderRadius: '2px'
    };

    const [nameFieldCss, setFieldCss] = React.useState();
    const tmpCss = {
        borderLeft: "1px solid #ced4da",
        paddingLeft: "2px",
        textOverflow: "ellipsis",
        display: "inline-block",
        whiteSpace: "nowrap",
        margin: "auto",
    };

    React.useEffect(() => {
        if (text) {
            let width = text.length * 20;
            setFieldCss({
                width: width + "px",
                ...tmpCss,
            });
        }
    }, [text]);

    React.useEffect(() => {
        if (onTap) {
            setFieldCss({
                color: "blue",
                cursor: "pointer",
                ...tmpCss,
            });
        }
    }, [onTap]);

    const openDialog = () => {
        if (!controlDisabled) {
            setOpenDialog(true);
        }
    };

    const closeModal = () => {
        setOpenDialog(false);
    };

    const keyDown = (event) => {
        if (text && !allowKeyDown) {
            event.preventDefault();
            return false;
        }
    };

    const onInputChange = (event) => {
        setInputValue(event.target.value)
        // onChange({ value: event.target.value });
    }

    const getChooseData = (event) => {
        closeModal();
        if (Array.isArray(event) && event.length > 0) {
            event = event[0];
        }
        setText(event[textField ? textField : "name"]);
        if (textField2 && event?.[textField2]) {
            setText(event[textField ? textField : "name"] + ' | ' + event[textField2 ? textField2 : "name"]);
        }
        if (onChange) {
            onChange({ value: event[valueField ? valueField : "id"] });
        }
        if (onResult) {
            onResult(event);
            setChosenData(event);
        }
    };

    const onFocusTmp = () => {
        setFocus(true);
    };

    const onBlurTmp = () => {
        setFocus(false);
    };

    const onBlurInput = () => {
        if (onChange && !isOpenDialog) {
            onChange({ value: inputValue });
        }
    }

    const onTextClick = () => {
        if (onTap) {
            onTap(chosenData);
        }
    }

    React.useEffect(() => {
        setInputValue(value)
    }, [value])
    
    React.useEffect(() => {
        setText(browseText)
    }, [browseText])
    return (
        <div>
            <label className="block text-sm font-medium text-gray-700">
                <span>
                    {label}
                    {required && <span className="k-required">*</span>}
                </span>
                <div className={`fiba-browse-wrap mt-1 ${focused ? "fiba-browse-focused" : ""}`} style={{ display: "flex" }} ref={divRef} onFocus={onFocusTmp} onBlur={onBlurTmp}>
                    <input
                        type={"text"}
                        className={"k-input k-input-md k-rounded-md k-input-solid"}
                        required={required}
                        {...others}
                        valid="true"
                        value={inputValue}
                        touched="true"
                        modified="true"
                        onChange={onInputChange}
                        onKeyDown={keyDown}
                        onBlur={onBlurInput}
                        disabled={props.disabled || controlDisabled ? "disabled" : undefined}
                    />
                    {viewName && (
                        <a style={nameFieldCss} ref={textFieldRef} onClick={onTextClick}>
                            {text}
                        </a>
                    )}
                    <span disabled={controlDisabled ? "disabled" : undefined} style={browseBtnCss} onClick={openDialog} className="k-select">
                        <i className={"fiba-i-search"}></i>
                    </span>
                    {visited && validationMessage && <Error>{validationMessage}</Error>}
                    {fieldInfo && <span className="k-field-info">*{fieldInfo}</span>}
                </div>
            </label>
            {isOpenDialog && (
                <Dialog title={title ? title : "Choose item"} width={width ? width : "60vw"} height={height ? height : "70vh"} onClose={closeModal}>
                    <DialogScreen chooseData={getChooseData} setData={getChooseData} visibleDialog={isOpenDialog} passdata={passdata} />
                </Dialog>
            )}
        </div>
    );
});
