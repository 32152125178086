import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FibaDropdown, FibaPhotoInput, FibaTextArea, Form, send, useFibaMsgBox, useLoading } from '../../components'
import { Field } from '@progress/kendo-react-form'
import FibaButton from '../../components/button/FibaButton/FibaButton'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import { Button } from "@progress/kendo-react-buttons";

const Violation = () => {
    const { t } = useTranslation()
    const [step, setStep] = useState(1);
    const [imgid, setSteImgid] = useState();
    const { error, success, confirm } = useFibaMsgBox();
    const { showLoading } = useLoading()
    const navigate = useNavigate()

    const responseCallBack = (data) => {
        setSteImgid(data)
        if (data) {
            setStep(step + 1)
        }
    }

    const submitData = async (data) => {
        const isconfirm = await confirm(t('confirmsave'));
        if (isconfirm) {
            const res = await send("lo010107", data, showLoading);
            if (res.status === 'error') {
                error(res.message)
            } else {
                success(t('success'));
                navigate('/');
            }
        }
    }

    return (
        <div>
            <Form
                read={'lo010107'}
                showAction={false}
                onSubmit={submitData}
            >
                <div className={`flex justify-center mt-8 ${step === 1 ? '' : 'sr-only'}`}>
                    <div>
                        <Field
                            name={'photo'}
                            component={FibaPhotoInput}
                            label={t('photo')}
                            required={true}
                            placeholder={t('photo')}
                            width="200px"
                            height="220px"
                            text={'upload_violation'}
                            responseCallBack={responseCallBack}
                        />
                    </div>
                </div>

                <div className={`grid md:grid-cols-3 sm:grid-cols-2 gap-4 mb-2 mt-8 ${step === 2 ? '' : 'sr-only'}`}>
                    <Field
                        name={'violationrate'}
                        component={FibaDropdown}
                        label={t('violationrate')}
                        required={true}
                        placeholder={t('violationrate')}
                        dictCode={'DIC_005'}
                    />
                    <div className="col-span-full">
                        <Field
                            name={'violationclause'}
                            component={FibaTextArea}
                            label={t('violationclause')}
                            rows="3"
                            required={true}
                            placeholder={t('violationclause')}
                        />
                        <Field
                            name={'violationdefinition'}
                            component={FibaTextArea}
                            label={t('violationdefinition')}
                            rows="3"
                            required={true}
                            placeholder={t('violationdefinition')}
                        />
                        <Field
                            name={'descriptionoffending'}
                            component={FibaTextArea}
                            label={t('descriptionoffending')}
                            rows="3"
                            required={true}
                            placeholder={t('descriptionoffending')}
                        />
                    </div>
                </div>

                {imgid && <div className='flex justify-center mt-20 space-x-10'>
                    {step > 1 && <Button fillMode="outline" icon={"arrow-left"}
                        onClick={() => setStep(step - 1)}
                        themeColor={'primary'}
                    >
                        {t('preview')}
                    </Button>}
                    {step > 1 ? <FibaButton themeColor='secondary' type={'submit'}>
                        {t('save')}
                    </FibaButton> :
                        <Button
                            fillMode="outline"
                            themeColor={'secondary'}
                            onClick={() => setStep(step + 1)}
                            type='button'
                        >
                            <span className='flex'>
                                {t('next')}
                                <ArrowRightIcon className='w-5 h-5' />
                            </span>

                        </Button>}
                </div>}
            </Form>
        </div>
    )
}

export default Violation