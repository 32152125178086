import * as React from "react";
import { Error } from "@progress/kendo-react-labels";

export const FibaCheckbox = (fieldRenderProps) => {
    const {
        // The meta props of the Field.
        validationMessage,
        touched,
        visited,
        modified,
        valid,
        label,
        required,
        fieldInfo,
        disabled,
        isPadding,
        // The input props of the Field.
        onFocus,
        onBlur,
        spaceLabel = true,
        // The custom props that you passed to the Field.
        ...others
    } = fieldRenderProps;
    let value = fieldRenderProps.value ? 1 : 0;

    const clickLable = () => {
        if (!disabled && fieldRenderProps.onChange) {
            fieldRenderProps.onChange({
                value: !value ? 1 : 0,
            });
        }
    }
    const onValueChange = React.useCallback(() => {
        // onChange callback expects argument with 'value' property
        if (!disabled) {
            fieldRenderProps.onChange({
                value: !value ? 1 : 0,
            });
        }
    }, [disabled, fieldRenderProps, value]);
    return (
        <div onFocus={onFocus} onBlur={onBlur}>
            {spaceLabel && <label>
                &nbsp;
            </label>}
            <div className={'flex items-end'}>
                <input type={"checkbox"} className={"h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"} onChange={onValueChange} checked={value} id={others.id} />
                <label className={"ml-2 block text-sm text-gray-900"} htmlFor={others.id} onClick={clickLable}>
                    {label}
                    {required && <span className="k-required">*</span>}
                    {fieldInfo && <span className="k-field-info">{fieldInfo}</span>}
                </label>
                {
                    // Display an error message after the "visited" or "touched" field is set to true.
                    visited && validationMessage && <Error>{validationMessage}</Error>
                }
            </div>
        </div>
    );
};
