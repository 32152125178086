import { Disclosure, Menu } from '@headlessui/react'
import { ArrowRightOnRectangleIcon, UserCircleIcon, UserIcon } from '@heroicons/react/24/outline'
import { Link, useNavigate } from 'react-router-dom'
import { ChangeLanguage, send, useFibaMsgBox, useLoading } from '../components';
import { useTranslation } from 'react-i18next';
import { FibaBreadcrumb } from '../components/breadcrumbs/FibaBreadcrumb/FibaBreadcrumb';
import Notification from './Notification';
import { useEffect, useState } from 'react';

export default function Header() {

    const navigate = useNavigate();
    const { showLoading } = useLoading()
    const { error } = useFibaMsgBox()
    const { t } = useTranslation()
    const [unReadNotifCount, setUnreadNotifCount] = useState(0);

    const logout = async () => {
        const res = await send('lo010101', {}, showLoading);
        if (res.status === 'error') {
            error(res.message)
        } else {
            navigate('/login')
        }
    }

    //API_GETUNREAD_NOTIFICATION
    const getNotificationUnreadCount = async () => {
        const res = await send('lo000601', {}, showLoading)
        if (res.status === 'error') {
            error(res.message);
        } else {
            setUnreadNotifCount(res);
        }
    }

    useEffect(() => {
        // getAllNotifications();
        getNotificationUnreadCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Disclosure as="nav" className="bg-white shadow object-cover bg-khanaltai_pattern bg-cover">
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <div className="relative flex h-16 justify-between">
                        <div className="flex flex-1 items-center justify-start">
                            <Link
                                to={"/"}
                            >
                                <img
                                    alt="Хан-Алтай"
                                    src="/mecore-logos/main-logo/logo_4.png"
                                    className="h-8 w-auto"
                                />
                            </Link>
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <Notification unReadNotifCount={unReadNotifCount} setUnreadNotifCount={setUnreadNotifCount} />

                            {/* Profile dropdown */}
                            <Menu as="div" className="relative ml-3">
                                <div>
                                    <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                        <span className="absolute -inset-1.5" />
                                        <span className="sr-only">Open user menu</span>
                                        <UserCircleIcon className="h-8 w-8" aria-hidden="true" />
                                    </Menu.Button>
                                </div>
                                <Menu.Items
                                    transition={"true"}
                                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                    <Menu.Item>
                                        <Link
                                            to="/profile"
                                            className="flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <UserIcon className='h-5 w-5' />&ensp;{t('profile')}
                                        </Link>
                                    </Menu.Item>
                                    <ChangeLanguage />
                                    <Menu.Item>
                                        <div
                                            onClick={logout}
                                            className="flex px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">
                                            <ArrowRightOnRectangleIcon className='h-5 w-5' />&ensp;{t('logout')}
                                        </div>
                                    </Menu.Item>
                                </Menu.Items>
                            </Menu>
                        </div>
                    </div>
                </div>
            </Disclosure>
            <FibaBreadcrumb emptyJumpUrl={[]} />
        </>

    )
}
