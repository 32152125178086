import * as React from "react";
import { useTranslation } from "react-i18next";

export const CommandCell = props => {
    const { t } = useTranslation();
    const {
        dataItem,
        dataCount,
        optionalIcon = <i className="fiba-i-hmore"/>
    } = props;
    if (!props.dataItem) {
        return <td className="k-command-cell"></td>
    }
    const inEdit = dataItem[props.editField];
    return inEdit ? (<td className="k-command-cell">
        <button
            className="mr-1 inline-flex items-center rounded-full border border-transparent bg-primary-600 p-2 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            onClick={() => props.save(dataItem)}
            title={t('save')}
            type="button"
        >
            <i className="fiba-i-save" />
        </button>
        <button
            className="inline-flex items-center rounded-full border border-transparent bg-rose-500 p-2 text-white shadow-sm hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            title={t('cancel')}
            onClick={() => props.cancel(dataItem)}
            type="button"
        >
            <i className="fiba-i-cancel" />
        </button>
    </td>) : (
        <td className="k-command-cell space-x-1">
            {props.edit && <button
                className="mr-1 inline-flex items-center rounded-full border border-transparent bg-primary-500 p-2 text-white shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                onClick={() => props.edit(dataItem)}
                title={t(props.editTitle ? props.editTitle : 'edit')}
                type="button"
            >
                <i className="fiba-i-edit" />
            </button>}
            {props.optional && <button
                className="mr-1 inline-flex items-center rounded-full border border-transparent bg-primary-500 p-2 text-white shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                title={props.optionalTitle ? props.optionalTitle : t('text.optional')}
                onClick={() => props.optional(dataItem)}
            >
                {true && optionalIcon}
            </button>}
            {((!dataItem.intervalno || dataCount === dataItem.intervalno) && props.remove) && <button
                className="inline-flex items-center rounded-full border border-transparent bg-rose-600 p-2 text-white shadow-sm hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                title={props.deleteTitle ? props.deleteTitle : t('delete')}
                onClick={() => props.remove(dataItem)}
                type="button"
            >
                <i className="fiba-i-delete" />
            </button>}
        </td>
    );
};
