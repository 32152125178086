import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import Header from '../layouts/Header'

const PrivateRoute = () => {
    const { isAuth } = useAuth()

    return isAuth() ?
        <div className='h-[100vh]'>
            <Header />
            <main className="py-5">
                <div className="px-4 sm:px-6 lg:px-8">
                    <Outlet />
                </div>
            </main>
        </div>
        : <Navigate to="/login" />;
}

export default PrivateRoute
