import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { GRID_COL_INDEX_ATTRIBUTE } from '@progress/kendo-react-grid';
import React from 'react'
import { formatNumber } from '@progress/kendo-intl';

const FloatCell = (props) => {
    const field = props.field || "";
    const value = formatNumber((props.dataItem[field] ?? 0), 'n');
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    return (
        <td
            style={{
                textAlign: 'right',
            }}
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{
                [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...navigationAttributes}
        >
            {value}
        </td>
    );
}

export default FloatCell
