import React from 'react'
import { useFibaMsgBox } from "./hooks/useFibaMsgBox";
import { useTranslation } from 'react-i18next'
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { CONFIRM_BOX } from "./store/MsgReducer";

export const FibaMessageBox = () => {
    const { onConfirm, onCancel, msgBoxState } = useFibaMsgBox();
    const { t } = useTranslation()
    const component = msgBoxState.show ? (
        <Dialog onClose={onCancel} width={400} className="fiba-messagebox-parent">
            <div className={`fiba-messagebox fiba-messagebox-${msgBoxState.type}`}>
                <div className="fiba-messagebox-title">{t(msgBoxState.title)}</div>
                <span className="fiba-messagebox-icon">
                    <i aria-hidden="true"
                        className={msgBoxState.icon}></i>
                </span>
                <div className="fiba-messagebox-body">
                    {msgBoxState.text}
                </div>
                {msgBoxState.body &&
                    <div className="fiba-messagebox-body-bottom">
                        {msgBoxState.body}
                    </div>
                }
                {
                    (msgBoxState.type === CONFIRM_BOX) ?
                        <DialogActionsBar>
                            <button
                                onClick={onConfirm}
                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-secondary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                            >
                                {t("yes")}
                            </button>
                            <button
                                className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                onClick={onCancel}
                            >
                                {t("no")}
                            </button>
                        </DialogActionsBar> :
                        <DialogActionsBar layout="center">
                            <button
                                className="mt-5 sm:mt-6 inline-flex justify-center rounded-md border border-transparent bg-secondary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                onClick={onConfirm}
                            >
                                {t("close")}
                            </button>
                        </DialogActionsBar>
                }
            </div>
        </Dialog>
    ) : null;
    return component;
};
