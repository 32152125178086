import { useState } from 'react';
import { Editor, EditorTools, EditorUtils, ProseMirror } from "@progress/kendo-react-editor";

const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
} = EditorTools;

export const EditorConfig = ({ content, handleChange }) => {
    const [value, setValue] = useState(
        EditorUtils.createDocument(
            new ProseMirror.Schema({
                nodes: EditorUtils.nodes,
                marks: EditorUtils.marks,
            }),
            !!content ? content : "<p>Editor sample content</p>"
        )
    );
    const onChange = (event) => {
        setValue(event.value);
        handleChange(event.html)
    };
    return (
        <Editor
            value={value}
            onChange={onChange}
            contentStyle={{
                height: 150,
                width: '100%'
            }}
            tools={[
                [Bold, Italic, Underline, Strikethrough],
                [Subscript, Superscript],
                [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                [Indent, Outdent],
                [OrderedList, UnorderedList],
                FontSize,
                FontName,
                FormatBlock,
                [Undo, Redo],
                [Link, Unlink, InsertImage, ViewHtml],
                [InsertTable],
                [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                [DeleteRow, DeleteColumn, DeleteTable],
                [MergeCells, SplitCell],
            ]}
            defaultContent={content}
        />
    )
}
