import { Field } from "@progress/kendo-react-form";
import { useTranslation } from "react-i18next";
import { send, useLoading, Form, ChangeLanguage } from '../components';
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FibaPassInput } from "../components/input/input-text/FibaPassInput";
import { encrtypth } from "../service/CoreFunctions";

export default function ResetPassword() {
    const { t } = useTranslation();
    const { token } = useParams()
    const { showLoading } = useLoading()
    const [errorMsg, setError] = useState();
    const [infoMsg, setInfoError] = useState();

    const login = async (formdata) => {
        setError(null)
        setInfoError(null)
        const senddata = {
            token,
            password: encrtypth(formdata.password),
            password_confirmation: encrtypth(formdata.password_confirmation)
        };
        const res = await send("lo000400", senddata, showLoading, true);
        if (res.status === 'error') {
            setError(res.message)
        } else {
            setInfoError(res);
        }
    }

    return (
        <div
            className='object-cover bg-login_pattern min-h-screen bg-cover'
        >
            <div className="absolute top-1 right-2">
                <ChangeLanguage />
            </div>

            <Form onSubmit={login} className="space-y-6" showAction={false}>
                <div className="flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md bg-black/50 rounded-md py-2">
                        <img
                            className="mx-auto h-10 w-auto"
                            src="/mecore-logos/main-logo/logo_4.png"
                            alt="Khan-Altai"
                        />
                        {/* <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Me Core</h2> */}
                    </div>

                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-white/70 py-8 px-4 shadow sm:rounded-lg sm:px-8">
                            <h2 className="mb-6 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                {t('reset_password')}
                            </h2>
                            <div className="space-y-3">
                                <Field 
                                    name={'password'} 
                                    component={FibaPassInput}
                                    label={t('new_password')}
                                    type="password"
                                    required={true}
                                    placeholder={t('password')}
                                />
                                <Field 
                                    name={'password_confirmation'} 
                                    component={FibaPassInput}
                                    type="password"
                                    label={t('password_confirmation')} required={true}
                                    placeholder={t('password_confirmation')}
                                />
                            </div>


                            {errorMsg &&
                                <div className="rounded-md bg-red-50 p-2 mt-2">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-red-800">{errorMsg}</h3>
                                        </div>
                                    </div>
                                </div>
                            }
                            {infoMsg &&
                                <div className="rounded-md bg-green-50 p-2 mt-2">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <CheckBadgeIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-green-800">{infoMsg}</h3>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="mt-7">
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md border border-transparent bg-secondary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2"
                                >
                                    {t('reset_password')}
                                </button>
                            </div>
                            <div className="flex justify-center mt-2">
                                <Link to={'/login'}
                                    className="font-medium text-secondary-600 hover:text-secondary-500">
                                    {t('login')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
};
