import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Error } from '@progress/kendo-react-labels';
import React from 'react'
import { getDicts } from "../../service/api-service/services";

export const FibaMultiSelect = ({
    dictCode, isChild = false, parentDicCode, parentValue, value, ...fieldRenderProps
}) => {
    const { validationMessage, visited, required, label, fieldInfo, name, ...others } = fieldRenderProps;

    const [data, setData] = React.useState(fieldRenderProps.data ? fieldRenderProps.data.slice() : []);
    const [loading, setLoading] = React.useState(false);

    const getDictsData = async () => {
        let parentObject = {};
        if (dictCode) {
            if (dictCode === 'DIC_002' || isChild) {
                if (parentDicCode || parentValue) {
                    parentObject = {
                        value: parentValue,
                        dictCode: parentDicCode
                    }
                } else {
                    parentObject = JSON.parse(localStorage.getItem(`DIC_002.${name}`));
                    if (!parentObject) {
                        return;
                    }
                }
            }
        }
        setLoading(true);
        const res = await getDicts({ dic_code: dictCode, parentValue: parentObject?.value, parentDicCode: parentObject?.dictCode });
        setLoading(false);
        if (res && res.status !== 'error') {
            setData(res.data ? res.data : res);
            // setTmpData(res.data ? res.data : res);
        }
    }

    if (value === null || value === undefined) {
        value = [];
    }

    React.useEffect(() => {
        getDictsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <label className="block text-sm font-medium text-gray-700">
                {label}
                {required && <span className='k-required'>*</span>}
                {fieldInfo && <span className='k-field-info'>{fieldInfo}</span>}
            </label>
            <div className={`mt-1`}>
                <MultiSelect
                    name={name}
                    data={data}
                    {...others}
                    required={required}
                    value={value}
                    loading={loading}
                    style={{ width: '100%' }}
                />
                {visited && validationMessage && <Error>{validationMessage}</Error>}
            </div>
            <input name={name} required={required} value={(value || value === 0) ? (value + '') : ""} className="sr-only ml-4" onChange={() => { }}></input>
        </div>
    );
}