import { OperatorChar } from "./operators"
import { Fields } from "./fields"
export const CustomFilterOperators = {
    "text": [
        {
            "text": Fields.filterEqOperator,
            "operator": OperatorChar.eq
        },
        {
            "text": Fields.filterContainsOperator,
            "operator": OperatorChar.contains
        }
    ],
    "numeric": [
        {
            "text": Fields.filterEqOperator,
            "operator": OperatorChar.eq
        },
        {
            "text": Fields.filterGteOperator,
            "operator": OperatorChar.gte
        },
        {
            "text": Fields.filterLteOperator,
            "operator": OperatorChar.lte
        },
    ],
    "date": [
        {
            "text": Fields.filterEqOperator,
            "operator": OperatorChar.eq
        },
        {
            "text": Fields.filterAfterOrEqualOperator,
            "operator": OperatorChar.gte
        },
        {
            "text": Fields.filterBeforeOrEqualOperator,
            "operator": OperatorChar.lte
        },
    ],
    "boolean": [
        {
            "text": Fields.filterEqOperator,
            "operator": OperatorChar.eq
        }
    ],
    "select": [
        {
            "text": Fields.filterEqOperator,
            "operator": OperatorChar.eq
        },
        {
            "text": Fields.filterNotEqOperator,
            "operator": OperatorChar.neq
        }
    ],
    "multiselect": [
        {
            "text": Fields.filterEqOperator,
            "operator": OperatorChar.in
        },
        {
            "text": Fields.filterNotEqOperator,
            "operator": OperatorChar.notin
        }
    ],
}