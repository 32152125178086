import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FibaPhotoInput, FibaTextArea, Form, send, useFibaMsgBox, useLoading } from '../../components'
import { Field } from '@progress/kendo-react-form'
import { Dialog } from "@progress/kendo-react-dialogs";

const InformationDecide = ({ modalState, closeModal, initdata }) => {

    const { t } = useTranslation()
    const [data, setData] = useState({})
    const { error } = useFibaMsgBox();
    const { showLoading } = useLoading()

    const getDetail = async () => {
        const res = await send("lo010110", { id: initdata.id }, showLoading);
        if (res.status === 'error') {
            error(res.message)
        } else {
            setData(res);
        }
    }

    const ShowDetail = () => {
        const details = [];

        for (const key in data.data) {
            if (Object.prototype.hasOwnProperty.call(data.data, key)) {
                const element = data.data[key];
                details.push(
                    <React.Fragment key={key}>
                        <div className='sm:text-right font-bold'>
                            {t(key)}:
                        </div>
                        <div>{element}</div>
                    </React.Fragment>
                );
            }
        }

        return <>{details}</>;
    };


    useEffect(() => {
        getDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {modalState && (
                <Dialog
                    title={t("decide")}
                    onClose={closeModal}
                    width={"90vw"}
                    height={"auto"}
                >
                    <Form
                        read="lo010113"
                        afterRedirect={false}
                        onFinish={closeModal ?? null}
                        initialValues={{
                            id: initdata.id,
                            userid: initdata.shiidwerlesen,
                        }}
                    >
                        <div className='grid sm:grid-cols-2 gap-2'>
                            <div className='sm:text-right font-bold'>
                                {t('created_at')}:
                            </div>
                            <div>{data.created_at}</div>
                            <ShowDetail />
                        </div>
                        <div className='mt-2'>
                            <div className={`flex justify-center mt-8`}>
                                <div>
                                    <Field
                                        name={'photo'}
                                        component={FibaPhotoInput}
                                        label={t('photo')}
                                        required={true}
                                        placeholder={t('photo')}
                                        width="200px"
                                        height="220px"
                                    />
                                </div>
                            </div>

                            <Field
                                name={'description'}
                                component={FibaTextArea}
                                label={t('description')}
                                rows="3"
                                required={true}
                                placeholder={t('description')}
                            />
                        </div>
                    </Form>
                </Dialog>
            )}
        </>
    )
}

export default InformationDecide