import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { GRID_COL_INDEX_ATTRIBUTE } from '@progress/kendo-react-grid';
import { Link, useLocation } from "react-router-dom";
import React from 'react'
import { ME_CORE_PATHNAME } from '../../../../constants/token.const';

const DetailCell = ({ dataItemKey = 'id', customDetailUrl, detailFuncion, ...props }) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    const location = useLocation()
    let detailUrl = location.pathname;
    if (customDetailUrl) {
        detailUrl = customDetailUrl;
    }

    const clickDetail = () => {
        
        let tmppath = {};
        if (localStorage.getItem(ME_CORE_PATHNAME)) {
            tmppath = JSON.parse(localStorage.getItem(ME_CORE_PATHNAME));
        }
        const paths = detailUrl.split('/');
        const listcode = paths[paths.length - 1];
        if (paths.length && !tmppath[listcode]) {
            tmppath[listcode] = {};
        }
        const id = props.dataItem['id'] ?? props.dataItem['dataItemKey'];
        const name = props.dataItem.name ?? value;
        tmppath[listcode][id] = name;
        localStorage.removeItem(ME_CORE_PATHNAME);
        localStorage.setItem(ME_CORE_PATHNAME, JSON.stringify(tmppath));
        if (detailFuncion) {
            detailFuncion(props.dataItem)
        }
    }
    return (
        <td
            style={{ ...props.style, ...{ color: 'blue' } }}
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            className={props.className}
            {...{
                [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...navigationAttributes}
        >
            {/* <div onClick={()=>{navigate(`${detailUrl}/${props.dataItem[dataItemKey]}`)}}>{value}</div> */}
            <Link to={detailFuncion ? `${window.location.search}` : `${detailUrl}/${props.dataItem[dataItemKey]}${window.location.search}`} onClick={clickDetail}>{value}</Link>
        </td>
    );
}

export default DetailCell
