/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useImperativeHandle, useCallback } from 'react'
import { Form as KendoForm, FormElement } from "@progress/kendo-react-form";
import PropTypes from 'prop-types'
import { useFibaMsgBox, useLoading } from "../features";
import { send } from '../service/api-service/services';
import FibaButton from '../button/FibaButton/FibaButton';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline';

export const Form = React.forwardRef(({
    initialValues, id, detail, showAction = true,
    render, setRender, userSendData = {}, foreignKey = {},
    afterRedirect = true, dataItemKey = 'id', setFormEvent,
    showBack = false, setDetailData, ...props
}, ref) => {

    const { showLoading } = useLoading();
    const { error, success, confirm } = useFibaMsgBox();
    const [showForm, setShowForm] = useState(false);
    const [iscreate, setIsCreate] = useState(true);
    const [defaultValue, setDefaultVal] = useState(initialValues);
    const [formrefs, setFormRefs] = useState();
    const navigate = useNavigate()
    const location = useLocation()
    const { t } = useTranslation()
    const pathsplit = location.pathname;
    let isSubmit = false;

    const onSubmit = async (data) => {
        if (!isSubmit) {
            return;
        }
        if (props.onSubmit) {
            props.onSubmit(data);
        } else {
            const isconfirm = await confirm('Хадгалахдаа итгэлтэй байна уу?');
            if (isconfirm) {
                let proc_code = "";
                if (props.read) {
                    proc_code = props.read;
                } else {
                    if (data[dataItemKey] && !iscreate) {
                        proc_code = props.update;
                    } else {
                        proc_code = props.create;
                    }
                }
                const res = await send(proc_code, { ...userSendData, ...data }, showLoading);
                if (!props.notShowMsg) {
                    if (res?.status === "error") {
                        error(res.message);
                    } else {
                        success(t('success'));
                        if (afterRedirect) {
                            navigate(pathsplit.substring(0, pathsplit.lastIndexOf('/')))
                        }
                        if (props.onFinish) {
                            props.onFinish(res)
                        }
                    }
                } else {
                    if (props.onFinish) {
                        props.onFinish(res)
                    }
                }
            }
        }
    }

    const onchange = (event) => {
        console.log(event);
    }

    const goBack = () => {
        navigate(-1)
    }

    const onSubmitClick = () => {
        isSubmit = true;
    }

    useEffect(() => {
        const getDetail = async () => {
            setShowForm(false);
            if (id && (id === 0 || id === '0')) {
                setDefaultVal(initialValues);
                setIsCreate(true);
            } else {
                if (id && id !== 0 && id !== '0' && detail) {
                    const snddata = foreignKey ?? {};
                    snddata[dataItemKey] = id;
                    setIsCreate(false);
                    const res = await send(detail, snddata, showLoading);
                    if (res?.status === "error") {
                        setShowForm(true);
                        error(res.message);
                    } else {
                        if (setDetailData) {
                            setDetailData(res)
                        }
                        setDefaultVal(res);
                        setShowForm(true);
                    }
                } else {
                    setShowForm(true);
                }
            }
        }
        getDetail();
    }, [id, detail])

    useEffect(() => {
        if (id === 0 || id === '0') {
            setShowForm(true);
        }
    }, [defaultValue])

    const formRef = useCallback((event) => {
        setFormRefs(event)
        if (setFormEvent && event) {
            setFormEvent(event)
        }
    }, []);

    useImperativeHandle(ref, () => ({
        onSubmitClick,
        formRef: formrefs
    }));

    return (
        <React.Fragment>
            {
                showForm && <KendoForm
                    onSubmit={onSubmit}
                    initialValues={defaultValue}
                    onChange={onchange}
                    {...props}
                    ref={formRef}
                    render={(formrender) => {
                        if (setRender) {
                            setRender(formrender)
                        }
                        return <React.Fragment>
                            <FormElement>
                                {(showBack || showAction) &&
                                    <React.Fragment>
                                        <div className="flex justify-between my-2">
                                            <div>
                                                {showBack &&
                                                    <FibaButton
                                                        onClick={goBack}
                                                        title={t('back')}
                                                        buttonType={'icon'}
                                                        icon={<ArrowLeftCircleIcon className="h-5 w-5" />}
                                                    >
                                                    </FibaButton>
                                                }
                                            </div>
                                            <div className='flex space-x-2'>
                                                {showAction &&
                                                    <React.Fragment>
                                                        <FibaButton type="button" themeColor='none' onClick={formrender.onFormReset}>
                                                            {t("cancel")}
                                                        </FibaButton>
                                                        <FibaButton type="submit" disabled={!formrender.allowSubmit} onClick={onSubmitClick}>
                                                            {props.submitText ?? t("save")}
                                                        </FibaButton>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                        <hr className='mb-3' />
                                    </React.Fragment>
                                }
                                {props.children}
                            </FormElement>
                        </React.Fragment>
                    }
                    }
                />
            }
        </React.Fragment>
    )
})

Form.propTypes = {
    read: PropTypes.string,
    create: PropTypes.string,
    update: PropTypes.string,
    notShowMsg: PropTypes.bool,
    onFinish: PropTypes.func,
    onSubmit: PropTypes.func,
}
