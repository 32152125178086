import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { GRID_COL_INDEX_ATTRIBUTE } from '@progress/kendo-react-grid';
import React from 'react'
import { FibaCheckbox } from '../../../input';

const CheckBoxCell = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    return (
        <td
            style={{
                color: 'blue',
            }}
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{
                [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...navigationAttributes}
        >
            {/* <Link to={`${location.pathname}/${props.dataItem.id}`}>{value}</Link> */}
            <FibaCheckbox value={value} disabled={true} spaceLabel={false}/>
        </td>
    );
}

export default CheckBoxCell
