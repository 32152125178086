import { Field } from "@progress/kendo-react-form";
import { useTranslation } from "react-i18next";
import { send, useLoading, Form } from '../components';
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { encrtypth } from "../service/CoreFunctions";
import { FibaPassInput } from "../components/input/input-text/FibaPassInput";
import { useNavigate } from "react-router-dom";

export default function ChangePassword() {
    const { t } = useTranslation();
    const { showLoading } = useLoading()
    const [errorMsg, setError] = useState();
    const [infoMsg, setInfoError] = useState();
    const navigate = useNavigate()

    const login = async (formdata) => {
        setError(null)
        setInfoError(null)
        const data = {
            old_password: encrtypth(formdata.old_password),
            password: encrtypth(formdata.password),
            password_confirmation: encrtypth(formdata.password_confirmation)
        }
        const res = await send("lo000300", data, showLoading);
        if (res.status === 'error') {
            setError(res.message)
        } else {
            setInfoError(res);
            navigate('/')
        }
    }

    return (
        <Form onSubmit={login} className="space-y-6" showAction={false}>
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-8">
                        <h2 className="mb-6 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            {t('changePassword')}
                        </h2>
                        <div className="space-y-3">
                            <input autoComplete="username" className="hidden" name="username"/>
                            <Field
                                name={'old_password'} 
                                component={FibaPassInput}
                                label={t('old_password')}
                                required={true}
                                placeholder={t('old_password')}
                                autoComplete={'current-password'}
                            />
                            <Field 
                                name={'password'} component={FibaPassInput}
                                label={t('new_password')}
                                type="password"
                                required={true}
                                placeholder={t('password')}
                                autoComplete={'new-password'}
                            />
                            <Field 
                                name={'password_confirmation'}
                                component={FibaPassInput}
                                type="password"
                                label={t('password_confirmation')} required={true}
                                placeholder={t('password_confirmation')}
                                autoComplete={'new-password'}
                            />
                        </div>


                        {errorMsg &&
                            <div className="rounded-md bg-red-50 p-2 mt-2">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-red-800">{errorMsg}</h3>
                                    </div>
                                </div>
                            </div>
                        }
                        {infoMsg &&
                            <div className="rounded-md bg-green-50 p-2 mt-2">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <CheckBadgeIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-green-800">{infoMsg}</h3>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="mt-7">
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                            >
                                {t('changePassword')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    )
};
