import * as React from "react";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";

export const FibaInputNumber = (fieldRenderProps) => {
    const { validationMessage, visited, required, label, fieldInfo, name, ...others } = fieldRenderProps;
    let value = null;
    const ref = React.useRef(null);
    if (fieldRenderProps.value || fieldRenderProps.value === 0) {
        value = parseFloat(fieldRenderProps.value);
    }

    const handleOnWheel = event => {
        event.stopPropagation();
    };

    React.useEffect(() => {
        ref.current.element.addEventListener('wheel', handleOnWheel);

        return () => {
            if (ref.current && ref.current.element) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                ref.current.element.removeEventListener('wheel', handleOnWheel);
            }
        };
    });

    return (
        <div>
            <label className="block text-sm font-medium text-gray-700">
                {label}
                {required && <span className='k-required'>*</span>}
                {fieldInfo && <span className='k-field-info'>{fieldInfo}</span>}
            </label>
            <div className={`mt-1`}>
                <NumericTextBox ref={ref} name={name} {...others} required={required} value={value} />
                {visited && validationMessage && <Error>{validationMessage}</Error>}
            </div>
            <input name={name} required={required} value={(value || value === 0) ? (value + '') : ""} className="sr-only ml-4" onChange={() => { }}></input>
        </div>
    );
};
