import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FibaBrowse, Form, send, useFibaMsgBox, useLoading } from '../../components'
import { Field } from '@progress/kendo-react-form'
import { Dialog } from "@progress/kendo-react-dialogs";
import UserList from '../user/UserList';

const InformationShare = ({ modalState, closeModal, initdata }) => {

    const { t } = useTranslation()
    const [data, setData] = useState({})
    const { error } = useFibaMsgBox();
    const { showLoading } = useLoading()

    const getDetail = async () => {
        const res = await send("lo010110", { id: initdata.id }, showLoading);
        if (res.status === 'error') {
            error(res.message)
        } else {
            setData(res);
        }
    }

    const ShowDetail = () => {
        const details = [];

        for (const key in data.data) {
            if (Object.prototype.hasOwnProperty.call(data.data, key)) {
                const element = data.data[key];
                details.push(
                    <React.Fragment key={key}>
                        <div className='sm:text-right font-bold'>
                            {t(key)}:
                        </div>
                        <div>{element}</div>
                    </React.Fragment>
                );
            }
        }

        return <>{details}</>;
    };


    useEffect(() => {
        getDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {modalState && (
                <Dialog
                    title={t("share")}
                    onClose={closeModal}
                    width={"90vw"}
                    height={"80vh"}
                >
                    <Form
                        read="lo010112"
                        afterRedirect={false}
                        onFinish={closeModal ?? null}
                        initialValues={{
                            id: initdata.id,
                            userid: initdata.shiidwerlesen,
                        }}
                    >
                        <div className='grid sm:grid-cols-2 gap-2'>
                            <div className='sm:text-right font-bold'>
                                {t('created_at')}:
                            </div>
                            <div>{data.created_at}</div>
                            <ShowDetail />
                        </div>
                        <div className='mt-2'>

                            <Field
                                name={"userid"}
                                component={FibaBrowse}
                                dialogscreen={UserList}
                                label={t("udirdlaga")}
                                required={true}
                                textField="name"
                                viewName={true}
                                width="90vh"
                                title="Хэрэглэгч сонгох"
                                passdata={[
                                    { field: 'role', value: 3, cond: '=' },
                                ]}
                            />

                        </div>
                    </Form>
                </Dialog>
            )}
        </>
    )
}

export default InformationShare