import React, { useState } from 'react'
import { GridColumn } from '@progress/kendo-react-grid'
import { useTranslation } from 'react-i18next'
import { ListScreen, send, useFibaMsgBox, useLoading } from '../../components'
import { INFORMATIONSTATUS, INFORMATIONTYPE } from '../../constants/data.cont'
import InformationShare from './InformationShare'
import InformationDetail from './InformationDetail'
import { useAuth } from '../../contexts/AuthContext'
import InformationDecide from './InformationDecide'
import { useLocation } from 'react-router-dom'

const Information = () => {

    const { t } = useTranslation()
    const gridRef = React.useRef(null);
    const { error, success, confirm } = useFibaMsgBox();
    const { showLoading } = useLoading()
    const { auth } = useAuth()
    const location = useLocation();
    let dataItem = location.state ? location.state.dataitem : null;
    const [dataDetail, setDataDetail] = useState({
        showDetail: dataItem ? true : false, data: dataItem ? dataItem : {}
    });
    const [informationShare, setInformationShare] = useState({
        showDetail: false, data: {}
    });
    const [successdata, setDataSuccess] = useState({
        showDetail: false, data: {}
    });

    const roleColumn = ({ dataItem }) => {
        let rolename = "";
        for (let index = 0; index < INFORMATIONTYPE.length; index++) {
            const element = INFORMATIONTYPE[index];
            if ((element.value + '') === (dataItem.type + '')) {
                rolename = element.name;
            }
        }
        return <td>
            {t(rolename)}
        </td>
    }

    const remove = async (data) => {
        const isconfirm = await confirm(t('confirmdelete'));
        if (isconfirm) {
            const res = await send("lo010109", data, showLoading);
            if (res.status === 'error') {
                error(res.message)
            } else {
                success(t('success'));
                refreshData();
            }
        }
    }


    const CommandCell = ({ dataItem }) => {
        return <td>
            <button
                className="mr-1 inline-flex items-center rounded-full border border-transparent bg-primary-500 sm:p-1 p-2 text-white shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                onClick={() => openDialogDetail(dataItem)}
                title={t('more')}
                type="button"
            >
                <i className="fiba-i-edit" />
            </button>
            {dataItem.statusid === 2 && <button
                className="mr-1 inline-flex items-center rounded-full border border-transparent bg-primary-500 sm:p-1 p-2 text-white shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                onClick={() => openDialogSuccess(dataItem)}
                title={t('decide')}
                type="button"
            >
                <i className="fiba-i-success" />
            </button>}
            {(auth.user.role < 3 && dataItem.statusid < 3) && <>
                <button
                    className="mr-1 inline-flex items-center rounded-full border border-transparent bg-primary-500 sm:p-1 p-2 text-white shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    onClick={() => openDialogShare(dataItem)}
                    title={t('share')}
                    type="button"
                >
                    <i className="fiba-i-profile" />
                </button>
                <button
                    className="inline-flex items-center rounded-full border border-transparent bg-rose-600 sm:p-1 p-2 text-white shadow-sm hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    title={t('delete')}
                    onClick={() => remove(dataItem)}
                    type="button"
                >
                    <i className="fiba-i-delete" />
                </button>
            </>}

        </td>
    }

    const statusColumn = ({ dataItem }) => {
        let rolename = "";
        for (let index = 0; index < INFORMATIONSTATUS.length; index++) {
            const element = INFORMATIONSTATUS[index];
            if ((element.value + '') === (dataItem.statusid + '')) {
                rolename = element.name;
            }
        }
        return <td>
            {t(rolename)}
        </td>
    }

    const refreshData = () => {
        if (gridRef.current) {
            gridRef.current.readData();
        }
    };

    const openDialogDetail = async (dataItem) => {
        setDataDetail({ showDetail: true, data: dataItem });
    }

    const openDialogSuccess = async (dataItem) => {
        setDataSuccess({ showDetail: true, data: dataItem });
    }

    const openDialogShare = async (dataItem) => {
        setInformationShare({ showDetail: true, data: dataItem });
    }

    return (
        <div>
            <ListScreen
                read={'lo010108'}
                delete={'lo010109'}
                createable={false}
                ref={gridRef}
                deleteable={false}
                showDetail={false}
            >
                <GridColumn field='id' title={t('id')} filter='numeric' width={80}></GridColumn>
                <GridColumn
                    field='type' title={t('type')}
                    filter='select'
                    cell={roleColumn}
                    constData={INFORMATIONTYPE}
                    width={150}
                ></GridColumn>
                <GridColumn field='userid' title={t('userid')} filter='numeric' width={80}></GridColumn>
                <GridColumn field='username' title={t('created_by')} filter='text' width={150}></GridColumn>
                <GridColumn field='created_at' title={t('created_at')} filter='date' width={160}></GridColumn>
                <GridColumn
                    field='statusid'
                    title={t('status')} filter='select'
                    cell={statusColumn}
                    constData={INFORMATIONTYPE}
                    width={120}
                >
                </GridColumn>
                <GridColumn
                    cell={CommandCell}
                    width={140}
                >
                </GridColumn>

            </ListScreen>
            {dataDetail.showDetail && (
                <InformationDetail
                    closeModal={() => {
                        setDataDetail({ showDetail: false });
                        refreshData();
                    }}
                    modalState={dataDetail.showDetail}
                    data={dataDetail.data}
                />
            )}
            {informationShare.showDetail && (
                <InformationShare
                    closeModal={() => {
                        setInformationShare({ showDetail: false });
                        refreshData();
                    }}
                    modalState={informationShare.showDetail}
                    initdata={informationShare.data}
                />
            )}
            {successdata.showDetail && (
                <InformationDecide
                    closeModal={() => {
                        setDataSuccess({ showDetail: false });
                        refreshData();
                    }}
                    modalState={successdata.showDetail}
                    initdata={successdata.data}
                />
            )}
        </div>
    )
}

export default Information