import React from 'react'
import { Error } from '@progress/kendo-react-labels'
import { Editor, EditorTools } from "@progress/kendo-react-editor";

const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    ForeColor,
    BackColor,
    CleanFormatting,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    NumberedList,
    BulletedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    InsertFile,
    SelectAll,
    Print,
    Pdf,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
} = EditorTools;

const tools = [
    [Bold, Italic, Underline, Strikethrough],
    [Subscript, Superscript],
    ForeColor,
    BackColor,
    [CleanFormatting],
    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
    [Indent, Outdent],
    [OrderedList, UnorderedList],
    [NumberedList, BulletedList],
    FontSize,
    FontName,
    FormatBlock,
    [SelectAll],
    [Undo, Redo],
    [Link, Unlink, InsertImage, ViewHtml],
    [InsertTable, InsertFile],
    [Pdf, Print],
    [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
    [DeleteRow, DeleteColumn, DeleteTable],
    [MergeCells, SplitCell],
];

export const RichTextEditor = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        id,
        label,
        layout,
        valid,
        disabled,
        hint,
        required,
        fieldInfo,
        visited,
        name,
        height = 300,
        onChange,
        value,
        ...others
    } = fieldRenderProps;
    const [valueF, setValue] = React.useState(value)
    const onChangeF = (event) => {
        setValue(event.html);
        if (onChange) {
            onChange({value: event.html})
        }
    };

    return (
        <div>
            <label htmlFor={name} className="block text-sm font-medium text-gray-700">
                {label}
                {required && <span className='k-required'>*</span>}
                {fieldInfo && <span className='k-field-info'>{fieldInfo}</span>}
            </label>
            <div className={`mt-1`}>
                <Editor
                    defaultContent={valueF}
                    onChange={onChangeF}
                    tools={tools}
                    contentStyle={{ height }}
                    {...others}
                />
                {visited && validationMessage && <Error>{validationMessage}</Error>}
            </div>
        </div>
    )
}