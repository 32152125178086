import { Error } from '@progress/kendo-react-labels'
import React from 'react'
import { send } from '../../service'
import { debounce } from "lodash";

export const FibaInput = (fieldRenderProps) => {
  const {
    validationMessage,
    visited,
    required,
    label,
    fieldInfo,
    disabled,
    value,
    name,
    modified,
    valid,
    touched,
    cyrillic2latin,
    render,
    ...others
  } = fieldRenderProps

  const cyrillic2latinFunction = async (event) => {
    if (others.onKeyUp) {
      others.onKeyUp(event);
    }
    if (cyrillic2latin && render) {
      const res = await send('gp040102', { name: event.target.value });
      if (res.status !== 'error') {
        render.onChange(cyrillic2latin, { value: res })
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = React.useCallback(debounce(cyrillic2latinFunction, 300), []);
  
  React.useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    }
  }, [debouncedChangeHandler]);

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
        {required && <span className='k-required'>*</span>}
        {fieldInfo && <span className='k-field-info'>{fieldInfo}</span>}
      </label>
      <div className={`mt-1`}>
        <input
          required={required}
          disabled={disabled}
          modified={modified ? modified + "" : ""}
          touched={touched ? touched + "" : ""}
          valid={valid ? valid + "" : ""}
          className="k-input k-input-md k-rounded-md k-input-solid"
          onKeyUp={debouncedChangeHandler}
          name={name}
          {...others}
          value={value || ''}
          style={{ width: '100%' }}
        />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    </div>

  )
}
