// import { OPERATORS_MAP } from "./constant";
// const dateToFormat = (date) => {
//     const d = new Date(date);
//     return d.getFullYear() + '-' + ("0" + (d.getMonth() + 1)).slice(-2) + '-' + ("0" + d.getDate()).slice(-2);
// }

// const isValidDate = (d) => {
//     return d instanceof Date && !isNaN(d);
// }

function getPageInfo(state) {
    return {
        perPage: state.take,
        page: Math.floor(state.skip / state.take) + 1
    }
}

// function gridToTitanFilter(filter, swap) {
//     const op = OPERATORS_MAP[filter.operator];
//     let value = '';
//     if (op === 'LIKE') {
//         if (filter.operator === 'startswith') {
//             value = filter.value + '%';
//         } else if (filter.operator === 'endswith') {
//             value = '%' + filter.value;
//         } else if (filter.operator === 'contains') {
//             value = '%' + filter.value + '%';
//         }
//     } else {
//         value = filter.value;
//         if (filter.value) {
//             if (isValidDate(filter.value)) {
//                 value = dateToFormat(filter.value);
//             }
//         }
//     }

//     const tFilter = {
//         cond: op,
//         value: (value || value === 0 || value === false) ? value : null,
//         field: filter.field
//     }
//     if (swap) {
//         if (swap[tFilter.field]) {
//             tFilter.field = swap[tFilter.field];
//         }
//     }
//     return tFilter;
// }

// function getGridFilter({ swap = {}, ...state }) {
//     let filterData = [];
//     /**
//      * from Field -г to Field болгох эсэх
//      * @param  {} swap
//      */
//     if (state.filter) {
//         const filt = state.filter.filters;
//         if (filt && filt.length > 0) {
//             for (let index = 0; index < filt.length; index++) {
//                 const subFilter = filt[index];
//                 if (subFilter.filters && subFilter.filters.length > 0) {
//                     const logic = subFilter.logic;
//                     if (logic.toLowerCase() === 'or') {
//                         const value = [];
//                         let tmp;
//                         for (let ind = 0; ind < subFilter.filters.length; ind++) {
//                             tmp = subFilter.filters[ind];
//                             if (isValidDate(tmp.value)) {
//                                 value.push(dateToFormat(tmp.value));
//                             } else {
//                                 value.push(tmp.value);
//                             }
//                         }
//                         if (tmp) {
//                             tmp.value = value;
//                             filterData.push(gridToTitanFilter(tmp, swap));
//                         }
//                     } else {
//                         for (let ind = 0; ind < subFilter.filters.length; ind++) {
//                             const element = subFilter.filters[ind];
//                             filterData.push(gridToTitanFilter(element, swap));
//                         }
//                     }
//                 } else {
//                     filterData.push(gridToTitanFilter(subFilter, swap));
//                 }
//             }
//         }
//     }
//     return filterData;
// }

async function getListRequest({ sort = [], ...state }) {
    let requestData = {};
    const pageInfo = await getPageInfo(state);
    // const filterData = await getGridFilter(state);
    const sorts = [];
    for (let index = 0; index < sort.length; index++) {
        const element = sort[index];
        if (state.swap && state.swap[element.field]) {
            element.field2 = state.swap[element.field];
        }
        sorts.push({
            field: element.field2 ?? element.field,
            dir: element.dir
        });
    }
    requestData = {
        ...requestData,
        ...pageInfo,
        orders: sorts,
        // filters: filterData
        // getPageInfo(state)
    }
    return requestData;
}

const functions = {
    getListRequest
};

export default functions;
