/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import { send, useFibaMsgBox, useLoading } from '../components';
import { useState } from "react";
import { useEffect } from "react";

export default function Profile() {
  const { showLoading } = useLoading()
  const [info, setInfo] = useState();
  const { error } = useFibaMsgBox();


  useEffect(() => {
    getDetail();
  }, [])

  const getDetail = async () => {
    const res = await send("lo010100", {}, showLoading);
    if (res.status === 'error') {
      error(res.message)
    } else {
      setInfo(res);
    }
  }



  return (
    <>
      <main className="py-4">

        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-2 lg:col-start-1">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
                    {t('generalInfo')}
                  </h2>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">{t('id')}</dt>
                      <dd className="mt-1 text-sm text-gray-900">{info?.user?.id}</dd>
                    </div>
                    
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">{t('name')}</dt>
                      <dd className="mt-1 text-sm text-gray-900">{info?.user?.name}</dd>
                    </div>

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">{t('email')}</dt>
                      <dd className="mt-1 text-sm text-gray-900">{info?.user?.email}</dd>
                    </div>

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">{t('created_at')}</dt>
                      <dd className="mt-1 text-sm text-gray-900">{info?.user?.created_at}</dd>
                    </div>

                  </dl>
                </div>

              </div>
            </section>

          </div>
        </div>
      </main>
    </>
  )
}
