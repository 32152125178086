import * as React from "react";
import { Error, Hint } from "@progress/kendo-react-labels";
import { DatePicker, MultiViewCalendar } from "@progress/kendo-react-dateinputs";
import { dateFormat } from "../../service/functions";
export const CustomCalendar = (props) => {
    return <MultiViewCalendar {...props} views={1} />;
};

export const FibaDatePicker = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, name, valid, disabled, hint, wrapperStyle, required, hintDirection, value, defaultValue, onChange, ...others } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";
    const [valueF, setvalueF] = React.useState();

    const handleBlur = () => {
        onChange({value: dateFormat(valueF), target: {value: valueF}});
    }

    const handleChange = (event) => {
        setvalueF(event.value);
    }

    React.useEffect(() => {
        setvalueF(value ? new Date(value) : null)
    }, [value])

    return (
        <div>
            <label className="block text-sm font-medium text-gray-700">
                {label}
                {required && <span className='k-required'>*</span>}
            </label>
            <div className={"mt-1"}>
                <DatePicker
                    ariaLabelledBy={labelId}
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    valid={valid}
                    id={id}
                    disabled={disabled}
                    value={valueF}
                    required={required}
                    format={'yyyy-MM-dd'}
                    name={name}
                    formatPlaceholder={{
                        year: "yyyy",
                        month: "MM",
                        day: "dd",
                    }}
                    views={1}
                    calendar={CustomCalendar}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    {...others}
                    onBlur={handleBlur}
                />
                {showHint && (
                    <Hint id={hintId} direction={hintDirection}>
                        {hint}
                    </Hint>
                )}
                {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
            </div>
            <input name={name} required={required} value={value || ''} className="sr-only ml-4" onChange={() => { }}></input>
        </div>
    );
};

