export const Operator = {
    eq: 'eq',
    neq: 'neq',
    startswith: 'startswith',
    endswith: 'endswith',
    isnull: 'isnull',
    isnotnull: 'isnotnull',
    gte: 'gte',
    gt: 'gt',
    lte: 'lte',
    lt: 'lt',
    contains: 'contains'
}

export const OperatorChar = {
    eq: '=',
    neq: '!=',
    startswith: 'LIKE',
    endswith: 'LIKE',
    isnull: 'isnull',
    isnotnull: 'isnotnull',
    gte: '>=',
    gt: '>',
    lte: '<=',
    lt: '<',
    contains: 'LIKE',
    in: 'IN',
    notin: 'NOT IN',
}
