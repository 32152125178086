import React, { useState } from 'react'
import { GridColumn } from '@progress/kendo-react-grid'
import { useTranslation } from 'react-i18next'
import { ListScreen } from '../../components'
import { ADMIN_ROLES } from '../../constants/data.cont'
import UserDetail from './UserDetail'

export default function UserList({ chooseData, passdata = []}) {
    const { t } = useTranslation()
    const gridRef = React.useRef(null);
    const [dataDetail, setDataDetail] = useState({ showDetail: false, data: {} });
    const [userSendData] = useState({
        filters: passdata
    })

    const choosedData = (event) => {
        if (chooseData) {
            chooseData(event);
        }
    };

    const roleColumn = ({ dataItem }) => {
        let rolename = "";
        for (let index = 0; index < ADMIN_ROLES.length; index++) {
            const element = ADMIN_ROLES[index];
            if (element.value === dataItem.role) {
                rolename = element.name;
            }
        }
        return <td>
            {t(rolename)}
        </td>
    }

    const refreshData = () => {
        if (gridRef.current) {
            gridRef.current.readData();
        }
    };

    const openDialog = async (dataItem) => {
        setDataDetail({ showDetail: true, data: dataItem });
    }

    return (
        <div>
            <ListScreen
                read={'lo010103'}
                delete={'lo010104'}
                createable={false}
                deleteable={chooseData ? false : true}
                chooseData={choosedData}
                optional={openDialog}
                userSendData={userSendData}
                optionalIcon={<i className="fiba-i-edit" />}
                optionalTitle={t('changerole')}
                ref={gridRef}

                showDetail={false}
            >
                <GridColumn
                    field='role'
                    title={t('role')}
                    filter='select'
                    cell={roleColumn}
                    constData={ADMIN_ROLES}
                    width={150}
                >
                </GridColumn>
                <GridColumn field='lastname' title={t('lastname')} filter='text' width={150}/>
                <GridColumn field='name' title={t('name')} filter='text' width={150}></GridColumn>
                <GridColumn field='email' title={t('email')} filter='text' width={200}></GridColumn>
                <GridColumn field='created_at' title={t('created_at')} filter='date' width={150}></GridColumn>
            </ListScreen>
            {dataDetail.showDetail && (
                <UserDetail
                    closeModal={() => {
                        setDataDetail({ showDetail: false });
                        refreshData();
                    }}
                    modalState={dataDetail.showDetail}
                    data={dataDetail.data}
                />
            )}
        </div>
    )
}