import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import withValueField from "./FibaDropdownWithValue";
import { getDicts } from "../../service/api-service/services";
import { useTranslation } from "react-i18next";
const DropDownListWithValueField = withValueField(DropDownList);
export const FibaDropdown = React.forwardRef((fieldRenderProps, ref) => {
    const {
        validationMessage, visited, childField,
        required, label, fieldInfo, name,
        textField = 'name', valueField = 'value', defaultValue,
        dictCode, filterable, parentId,
        onChange, onResult,
        isChild = false, parentValue, parentDicCode,
        ...others
    } = fieldRenderProps;
    // console.log(fieldRenderProps.data);
    const { t } = useTranslation()
    let isNumberWithValue = false;
    if (fieldRenderProps.data) {
        for (let index = 0; index < fieldRenderProps.data.length; index++) {
            const element = fieldRenderProps.data[index];
            element[textField] = t(element[textField]);
            if (typeof element[valueField] != 'string') {
                isNumberWithValue = true;
            }
        }
    }
    const [data, setData] = React.useState(fieldRenderProps.data ? fieldRenderProps.data.slice() : []);
    const [tmpdata, setTmpData] = React.useState(fieldRenderProps.data ? fieldRenderProps.data.slice() : []);
    const [defaultItem, setDefaultVal] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const filterData = (filter) => {
        return filterBy(tmpdata, filter);
    };
    /**
     * dictCode props оор орж ирсэн үед дата дүүргэнэ.
     */
    const getDictsData = async () => {
        let parentObject = {};
        if (dictCode) {
            if (dictCode === 'DIC_002' || isChild) {
                if (parentDicCode || parentValue) {
                    parentObject = {
                        value: parentValue,
                        dictCode: parentDicCode
                    }
                } else {
                    parentObject = JSON.parse(localStorage.getItem(`DIC_002.${name}`));
                    if (!parentObject) {
                        return;
                    }
                }
            }
        }
        setLoading(true);
        const res = await getDicts({ dic_code: dictCode, parentValue: parentObject?.value, parentDicCode: parentObject?.dictCode });
        setLoading(false);
        if (res && res.status !== 'error') {
            setData(res.data ? res.data : res);
            setTmpData(res.data ? res.data : res);
        }
    }


    const filterChange = (event) => {
        const srchdata = filterData(event.filter);
        setData(srchdata);
    };
    
    let value = {};
    if (fieldRenderProps.value && typeof fieldRenderProps.value === 'object') {
        value = fieldRenderProps.value[valueField];
    } else {
        value = fieldRenderProps.value
        if (!value && value !== 0) {
            value = null;
        }
    }

    if (valueField === 'value' && (value || value === 0) && !isNumberWithValue) {
        value = value + "";
    }
    if (value && childField) {
        localStorage.setItem(`DIC_002.${childField}`, JSON.stringify({ value, dictCode }));
    }

    const onChangeValue = (event) => {
        if (onChange) {
            onChange(event)
        }
        if (onResult) {
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                if (element[valueField] === event.value) {
                    onResult(element);
                    break;
                }
            }
        }
    }

    React.useEffect(() => {
        const dt = {};
        dt[textField] = 'Сонгох...';
        dt[valueField] = null;
        getDictsData();
        setDefaultVal(dt)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // console.log(fieldRenderProps.data)
    const listNoDataRender = (element) => {
        const noData = (
            <h4
                style={{
                    fontSize: "1em",
                }}
            >
                <span
                    className="k-icon k-i-warning"
                    style={{
                        fontSize: "2.5em",
                    }}
                />
                <br />
                <br />
                Хоосон.
            </h4>
        );
        return React.cloneElement(element, { ...element.props }, noData);
    };
    return (
        <div>
            <label htmlFor={name} className="block text-sm font-medium text-gray-700">
                {label}
                {required && <span className="k-required">*</span>}
            </label>
            <div className={`mt-1`}>
                <DropDownListWithValueField
                    valueField={valueField}
                    textField={textField}
                    defaultItem={defaultItem}
                    data={data}
                    name={name}
                    onChange={onChangeValue}
                    id={name}
                    {...others}
                    required={required}
                    onFilterChange={filterChange}
                    value={value}
                    loading={loading}
                    filterable={filterable}
                    listNoDataRender={listNoDataRender}
                />
            </div>
            <input name={name} required={required} value={(value || value === 0) ? (value + '') : ""} id={name} className="sr-only ml-4" onChange={() => { }}></input>
            {fieldInfo && <span className="k-field-info">*{fieldInfo}</span>}
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
});
