import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from '../Auth/Login'
import NotFound from '../layouts/error/NotFound'
import PrivateRoute from './PrivateRoute'
import ChangePassword from '../Auth/ChangePassword'
import Profile from '../Auth/Profile'
import ForgotPassword from '../Auth/ForgotPassword'
import ResetPassword from '../Auth/ResetPassword'
import UserList from '../pages/user/UserList'
import Registration from '../pages/user/Registration'
import Menu from '../pages/Menu/Menu'
import Threat from '../pages/threat/Threat'
import Violation from '../pages/violation/Violation'
import Information from '../pages/Information/Information'

const loadingComp = <div className="k-loading-mask" style={{ zIndex: 100000 }}>
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
</div>

const MenuRoutes = () => {
    return (
        <Routes>
            <Route path="/threat/*" element={<Threat />} />
            <Route path="/violation/*" element={<Violation />} />
            <Route path="/employees/*" element={<UserList />} />
            <Route path="/problems/*" element={<Information />} />
        </Routes>
    );
};

export default function CoreRoutes() {
    return (
        <BrowserRouter>
            <Suspense fallback={loadingComp}>
                <Routes>
                    <Route exact path='/' element={<PrivateRoute />}>
                        <Route path="/" exact element={<Menu />} />
                        <Route path="/changePassword" exact element={<ChangePassword />} />
                        <Route path="/profile" exact element={<Profile />} />
                        <Route path="/user" exact element={<UserList />} />
                        <Route path="/menu/*" element={<MenuRoutes />} />
                    </Route>
                    {/* <PrivateRoute exact path="/login-history" element={LoginHistory} /> */}
                    <Route path="/login" element={<Login />} />
                    <Route path="/registration" exact element={<Registration />} />
                    <Route path="/forgotpassword" exact element={<ForgotPassword />} />
                    <Route path="/resetPassword/:token" exact element={<ResetPassword />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}
